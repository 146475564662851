// src/App.js
import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import Footer from './components/Footer';
import Whatwe from './components/WhatWe';
// import Navbar from './components/Navbar';
// import HeroSection from './components/HeroSection';
// import AboutSection from './components/AboutSection';
// import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <HeroSection/>
      <Whatwe/>
      <Footer/>
     
    </div>
  );
}

export default App;
