// src/components/HeroSection.js
import React from 'react';
import './HeroSection.css';

const HeroSection = () => {
  return (
    <div className="hero-section">
      <div className="hero-1"></div>
      <div className="hero-2"></div>
    </div>
  );
};

export default HeroSection;
