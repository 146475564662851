import React, { useState } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <h2 className="navbar-title">Devarai Overseas Pvt. Ltd</h2>
      <button
        className={`navbar-toggler ${isMenuOpen ? 'open' : ''}`}
        onClick={handleMenuToggle}
        aria-label="Toggle menu"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className={`navbar-menu ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="/#">Home</a></li>
          <li><a href="/#">About Us</a></li>
          <li><a href="/#">Products</a></li>
          <li><a href="/#">Services</a></li>
          <li><a href="/#">Our Team</a></li>
          <li><a href="/#">Contact Us</a></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
