// src/components/AboutSection.js
import React from 'react';
import './AboutSection.css';

const WhatWe = () => {
  return (
    <div className="about-section">
      <div className="about-container">
        <h2 className="about-title">What we are?</h2>
        <p className="about-description">
          Devarai Overseas Pvt Ltd is a premier company specializing in both international and national imports and exports. 
          With our extensive network of global and local partners, we are committed to providing top-notch products and 
          services that cater to diverse needs. Our deep industry knowledge and efficient logistics solutions position us as 
          a trusted leader in the global marketplace.
        </p>
        <div className="about-info">
          <div className="info-item">
            <h3>Our Mission</h3>
            <p>To deliver exceptional trade solutions and foster long-term relationships with our clients and partners.</p>
          </div>
          <div className="info-item">
            <h3>Our Vision</h3>
            <p>To be the most reliable and innovative partner in the import and export industry, known for our excellence and integrity.</p>
          </div>
          <div className="info-item">
            <h3>Our Values</h3>
            <p>Integrity, Excellence, Innovation, and Customer Focus.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWe;
